import { showSnack } from '@/helpers/globalHelpers';
import { replyAgreementNotification } from '@/store/modules/agreement/service/agreement.service';
import { listUserApprovers } from '@/store/modules/security/service/security.service';

export default {
	name: 'LegalReplyBox',
	props: {
		agreement: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			mode: 'INITIAL',
			valid: true,
			loadingUsers: false,
			loadingObserve: false,
			loadingApprover: false,
			loadingApproverAssign: false,

			userForm: {
				user: null,
			},
			isEnableForm: false,
			approvers: [],
			requiredRule: [(v) => !!v || 'Campo requerido'],
			observationValue: null,
		};
	},
	created() {},
	mounted() {},
	computed: {
		isLegalApprover() {
			const agreementStatus = this.agreement?.status;
			const hasAllowedRole =
				this.$store.getters['auth/hasRole']('Aprobador de Legal');
			if (agreementStatus === 'LEGAL_APROBACION') {
				return hasAllowedRole;
			} else if (agreementStatus === 'LEGAL_APROBACION_PREAPROBADO') {
				const nextApprover =
					this.agreement?.lastNotification?.next_user_approver_id;
				const isNextApprover = this.$store.state.auth?.user?.id == nextApprover;
				return hasAllowedRole && isNextApprover;
			}
			return false;
		},
		isEnabledReview() {
			const isPendingReview = this.agreement?.status == 'LEGAL_REVISION';
			const isLegalReviewer =
				this.$store.getters['auth/hasRole']('Revisor de Legal');

			if (isPendingReview && isLegalReviewer) {
				return true;
			}
			return false;
		},
	},
	watch: {
		mode(value) {
			if (value === 'FORM-ASSIGN') {
				this.getUsers();
			}
		},
	},
	methods: {
		getUsers: async function () {
			try {
				this.loadingUsers = true;
				const response = await listUserApprovers({
					agreement_id: this.agreement?.id,
				});
				if (response) {
					this.approvers = Array.isArray(response) ? response : [];
				}
			} catch (error) {
				showSnack(error?.response?.data?.message || error.toString(), 'warning');
			} finally {
				this.loadingUsers = false;
			}
		},

		sendReply: async function (bodyParams) {
			try {
				await replyAgreementNotification(this.agreement?.id, bodyParams);
				showSnack('Enviado', 'success');
				this.$emit('change');
			} catch (error) {
				showSnack(error?.response?.data?.message || error.toString(), 'warning');
			} finally {
				this.loadingObserve = false;
				this.loadingApprover = false;
				this.loadingApproverAssign = false;
			}
		},

		cleanForm: function () {
			this.$nextTick(() => {
				this.userForm.user = null;
				this.isEnableForm = false;
				if (this.$refs.userForm) this.$refs.userForm.reset();
			});
		},

		sendApprove: async function () {
			if (this.userForm?.user) {
				this.loadingApproverAssign = true;
				this.sendReply({
					approved: true,
					next_approver_user_id: parseInt(this.userForm?.user),
				});
			}
		},

		confirmApprove: async function () {
			const { isConfirmed } = await this.$swal.fire({
				title: null,
				text: '¿Está seguro de que no añadirá otro aprobador?',
				icon: 'question',
				showCancelButton: true,
				cancelButtonColor: '#9e9e9e',
				confirmButtonColor: '#0039a6',
				cancelButtonText: 'Volver atrás',
				confirmButtonText: 'Sí, estoy seguro',
				reverseButtons: true,
			});
			if (isConfirmed) {
				this.loadingApprover = true;
				this.sendReply({ approved: true });
			} else {
				this.cleanForm();
			}
		},

		confirmSending: async function (mode = 'APPROVE') {
			const { isConfirmed } = await this.$swal.fire({
				title: null,
				text: '¿Estás seguro de que deseas continuar con el proceso?',
				//text: '¿Está seguro de que desea enviar sus observaciones? Una vez enviadas no podrá realizar modificaciones a estas.',
				icon: 'question',
				showCancelButton: true,
				cancelButtonColor: '#e0e0e0',
				confirmButtonColor: '#0039a6',
				cancelButtonText: 'No',
				confirmButtonText: 'Sí',
				reverseButtons: true,
			});
			if (isConfirmed) {
				if (mode === 'APPROVE') {
					this.loadingApprover = true;
					this.sendReply({ approved: true });
				} else if (mode === 'OBSERVE') {
					this.loadingObserve = true;
					this.sendReply({
						approved: false,
						observation: this.observationValue,
					});
				}
			}
		},
	},
	components: {},
};
