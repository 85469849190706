import { VueEditor } from 'vue2-editor';
import { $EventBus } from '@/main';
import { mapActions, mapState } from 'vuex';
import CDatePicker from '@/components/global/CDatePicker.vue';
import ValidationAlert from '@/components/global/ValidationAlert.vue';
import DocumentDialog from '@/components/agreement/elements/DocumentDialog/DocumentDialog.vue';
import ContractSheetDialog from '@/components/agreement/elements/ContractSheetDialog/ContractSheetDialog.vue';

import ContractSheetTable from '@/components/agreement/elements/ContractSheetTable/ContractSheetTable.vue';
import { getStatus } from '@/helpers/statusAgreement.js';
import ObservationsTable from '@/components/agreement/elements/ObservationsTable/ObservationsTable.vue';

//import AddApproverDialog from '@/components/contract/agreement/elements/AddApproverDialog/LegalReplyBox.vue';
import LegalReplyBox from '@/components/contract/agreement/elements/LegalReplyBox/LegalReplyBox.vue';
import ApproverDialog from '@/components/agreement/elements/ApproverDialog/ApproverDialog.vue';
import DocumentsSection from '@/components/agreement/elements/DocumentsSection/DocumentsSection.vue';
import ApproversSection from '@/components/agreement/elements/ApproversSection/ApproversSection.vue';
import DownloadFileBtn from '@/components/agreement/elements/DownloadFileBtn/DownloadFileBtn.vue';
import AnnexSection from '@/components/agreement/elements/AnnexSection/AnnexSection.vue';
import TemplateChangesTable from '@/components/agreement/elements/TemplateChangesTable/TemplateChangesTable.vue';
import { replyAgreementNotification } from '@/store/modules/agreement/service/agreement.service';
import { formatDate, isObject, showSnack } from '@/helpers/globalHelpers';

export default {
	name: 'ReadAgreement',
	data() {
		return {
			valid: true,
			dialogSheet: false,
			dialogDocument: false,
			dialogSheetEdit: false,
			document: null,
			requiredRule: [(v) => !!v || 'Campo requerido'],
			numberRules: [
				(v) => !!v || 'Campo requerido',
				(v) => /^\d*$/.test(v) || 'Campo numérico',
				(v) => v > 0 || 'El valor debe ser mayor que cero',
			],
			headers: [
				{
					text: 'Modificado',
					value: 'created_at',
					sortable: false,
					align: 'left',
					class: 'primary--text',
				},
				{
					text: 'Añadido por:',
					value: 'user.fullName',
					sortable: false,
					align: 'left',
					class: 'primary--text',
				},
				{
					text: 'Comentarios',
					value: 'comment',
					sortable: false,
					align: 'left',
					class: 'primary--text',
				},
				{
					text: '',
					value: 'actions',
					sortable: false,
					align: 'left',
					class: 'primary--text',
				},
			],
			headersAgreementWord: [
				{
					text: 'Fecha',
					value: 'created_at',
					sortable: false,
					align: 'left',
					class: 'primary--text',
				},
				{
					text: 'Añadido por:',
					value: 'user.fullName',
					sortable: false,
					align: 'left',
					class: 'primary--text',
				},
				{
					text: '',
					value: 'actions',
					sortable: false,
					align: 'left',
					class: 'primary--text',
				},
			],
			loadingData: false,
			loadingObserve: false,
			loadingAccept: false,
			breadcrumbs: [
				{
					text: 'Inicio',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'Dashboard',
					},
				},
				{
					text: 'Legal',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'ContractHome',
					},
				},
				{
					text: 'Documento',
					disabled: true,
					href: '/',
				},
			],
			observation: null,
			hasObservations: false,
			approverDialog: false,
		};
	},
	created() {
		this.readAgreement();
	},
	mounted() {},
	computed: {
		...mapState('agreement', ['agreement']),

		agreementId() {
			return this.$route.params?.agreementId || null;
		},

		isActiveApprove() {
			// const statusNotification = this.agreement?.lastNotification?.status;
			// return statusNotification == 'PRE-APPROVED';

			let isEnable = false;
			const statusNotification = this.agreement?.lastNotification?.status;
			isEnable = statusNotification == 'APPROVED_SUPPLY';
			// if (statusNotification == 'APPROVED_SUPPLY') {
			// 	if (
			// 		this.agreement?.lastNotification?.pendingApprover &&
			// 		this.agreement?.lastNotification?.pendingApprover?.user_id ==
			// 			this.$store.state.auth?.user?.id
			// 	) {
			// 		isEnable = true;
			// 	}
			// 	// const userIdApprove = this.agreement?.lastNotification?.user_confirmed_id;
			// 	// isEnable = userIdApprove == null;
			// }
			return isEnable;
		},
		isActiveObservation() {
			let isEnable = false;
			const statusNotification = this.agreement?.lastNotification?.status;
			if (statusNotification == 'APPROVED_SUPPLY') {
				isEnable = true;
			} else if (statusNotification == 'PRE-APPROVED') {
				const userIdAuth = this.$store.state.auth?.user?.id;
				const userIdApprove = this.agreement?.lastNotification?.user_confirmed_id;
				isEnable = userIdApprove == userIdAuth;
			}
			return isEnable;
		},
		isFullWidth: function () {
			const statesList = [
				'LEGAL_REVISION',
				'LEGAL_APROBACION',
				'LEGAL_APROBACION_PREAPROBADO',
			];
			return !statesList.includes(this.agreement?.status);
		},
	},
	watch: {},
	methods: {
		...mapActions('agreement', [
			'getAgreement',
			'cleanAgreement',
			'updateAgreementNotification',
		]),

		getStatus,
		formatDate,

		async readAgreement() {
			if (this.agreementId) {
				this.loadingData = true;
				const { ok, error } = await this.getAgreement(this.agreementId);
				if (!ok) {
					let msm = error?.data?.message;
					this.showSnack(msm, 'error');
				}
				this.loadingData = false;
			}
		},

		showSnack(error, color = 'success') {
			const msmError = Array.isArray(error) ? error.join('<br> ') : error;
			$EventBus.$emit('showSnack', color, msmError);
		},

		getField(key) {
			const fields =
				Array.isArray(this.agreement?.agreementForm) &&
				this.agreement?.agreementForm;
			const found = fields.find((e) => e?.fieldAgreement?.key == key);
			return found?.value || null;
		},

		formatDateString(date) {
			return formatDate(date, 'll');
		},

		openDocumentDialog(v) {
			this.document = v;
			this.dialogDocument = true;
		},

		acceptContract() {
			this.loadingAccept = true;
			const dataSend = {
				agreementId: this.agreement?.lastNotification?.id,
				status: 'APPROVED',
			};
			this.updateStatusAgreement(dataSend);
		},

		async questionSend() {
			const { isConfirmed } = await this.$swal.fire({
				title: null,
				text: '¿Está seguro de que desea enviar sus observaciones? Una vez enviadas no podrá realizar modificaciones a estas.',
				icon: 'question',
				showCancelButton: true,
				cancelButtonColor: '#e0e0e0',
				confirmButtonColor: '#0039a6',
				cancelButtonText: 'No',
				confirmButtonText: 'Sí',
				reverseButtons: true,
			});
			if (isConfirmed) {
				this.sendObservation();
			}
		},

		sendObservation() {
			this.loadingObserve = true;
			const dataSend = {
				status: 'OBSERVED_LEGAL',
				observation: this.observation,
			};
			this.updateStatusAgreement(dataSend);
		},

		async updateStatusAgreement(bodyParams) {
			try {
				await replyAgreementNotification(this.agreement?.id, bodyParams);
				showSnack('¡Enviado!', 'success');
				this.$router.go(0);
			} catch (error) {
				showSnack(error?.response?.data?.message || error.toString(), 'warning');
			} finally {
				this.loadingObserve = false;
				this.loadingAccept = false;
			}
		},

		printSupplierUser: function () {
			const supplierUser = this.agreement?.user_provider_id;
			if (isObject(supplierUser)) {
				return `${supplierUser?.name || ''} ${
					supplierUser?.last_name_father || ''
				}`;
			}
			return '-';
		},
	},
	beforeDestroy() {
		this.cleanAgreement();
	},
	components: {
		ValidationAlert,
		CDatePicker,
		VueEditor,
		ContractSheetDialog,
		DocumentDialog,
		ContractSheetTable,
		ObservationsTable,
		LegalReplyBox,
		ApproverDialog,
		DocumentsSection,
		ApproversSection,
		DownloadFileBtn,
		AnnexSection,
		TemplateChangesTable,
	},
};
