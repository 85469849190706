<template>
	<read-agreement />
</template>
<script>
import ReadAgreement from '@/components/contract/agreement/readAgreement/ReadAgreement.vue';

export default {
	name: 'ReadAgreementByLegalView',
	components: { ReadAgreement },
};
</script>
