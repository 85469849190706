import { mapActions } from 'vuex';
import { $EventBus } from '@/main';

export default {
	name: 'ApproverDialog',
	props: {
		agreement: {
			type: Object,
			required: true,
		},
		disabled: {
			type: Boolean,
			required: false,
		},
	},
	data() {
		return {
			valid: true,
			loading: false,
			loadingSave: false,
			userForm: {
				user: null,
				signarute: null,
			},
			isEnableForm: false,
			approvers: [],
			requiredRule: [(v) => !!v || 'Campo requerido'],
			rules: [
				(v) => !!v || 'Campo requerido',
				(value) =>
					!value ||
					value.size < 2000000 ||
					'¡El tamaño de la imagen debe ser inferior a 2 MB!',
			],
		};
	},
	created() {
		//this.getUsers();
	},
	mounted() {},
	computed: {},
	watch: {},
	methods: {
		...mapActions('agreement', ['readApproverUsers', 'preApprovedNotification']),

		async sendApprove() {
			const isvalidated = this.$refs.userForm.validate();
			if (isvalidated) {
				this.loadingSave = true;
				let formData = new FormData();
				formData.append('status', 'APPROVED');
				formData.append('user_approved_id', this.$store.state.auth?.user?.id);
				formData.append(
					'file',
					this.userForm?.signarute,
					this.userForm?.signarute?.name
				);

				const data = {
					agreementId: this.agreement?.lastNotification?.id,
					form: formData,
				};
				const { ok, response, error } = await this.preApprovedNotification(data);
				if (ok) {
					this.$emit('change');
					this.showAlert(response.message, 'success');
					this.$nextTick(() => {
						this.userForm.user = null;
						this.isEnableForm = false;
						if (this.$refs.userForm) this.$refs.userForm.reset();
					});
				} else {
					this.showAlert(error?.data?.message);
				}
				this.loadingSave = false;
			}
		},

		showAlert(msmError, color = 'error') {
			//let msmError = error?.data?.message;
			msmError = Array.isArray(msmError) ? msmError.join('<br> ') : msmError;
			$EventBus.$emit('showSnack', color, msmError);
		},

		closeDialog() {
			this.$nextTick(() => {
				if (this.$refs?.form) {
					this.$refs.form.resetValidation();
				}
			});
		},

		async getUsers() {
			this.loading = true;
			const { ok, response, error } = await this.readApproverUsers();
			if (ok) {
				//const userIdAuth = this.$store.state.auth?.user?.id;
				const userIdAuth = 0;
				const approvers = Array.isArray(response.data) ? response.data : [];
				this.approvers = approvers.filter((user) => user?.id != userIdAuth);
			} else {
				this.showAlert(error?.data?.message || 'Error');
			}
			this.loading = false;
		},
	},
	components: {},
};
